<template>
  <div class="bg" style="height: 14.31rem">
    <el-container>
      <el-header class="bg-white df jc-sbn" style="line-height: 60px">
        <span style="font-size: 18px; font-weight: bold">方案列表</span>
        <div>
          <!-- <el-button>操作指引</el-button> -->
          <el-button type="primary" @click="dialogVisible = true">创建方案</el-button>
        </div>
      </el-header>
      <el-main>
        <el-row>
          <el-col :span="24">
            <div class="df f-w box">
              <div v-for="(item, idx) in videoList" :key="idx" @click="getItem(item)" class="item">
                <img :src="item.logo" object-fit="cover" alt="" />
                <div class="desc">
                  {{ item.title }}
                  <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
                </div>
                <div class="tools" @click.stop="activeI = item">
                  <el-dropdown trigger="click" placement="bottom" @command="handleClick">
                    <span class="el-dropdown-link">
                      <i class="el-icon-s-operation"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit" icon="el-icon-edit">编辑</el-dropdown-item>
                      <el-dropdown-item command="rename" icon="el-icon-edit-outline">重命名</el-dropdown-item>
                      <el-dropdown-item command="del" icon="el-icon-delete">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="38%">
      <el-input v-model="ipt" placeholder="请输入直播方案名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            ipt = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog class="live" destroy-on-close :visible.sync="openLive" :modal="false" width="48%" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-row>
        <el-col :span="8">
          <div class="imgBg">
            <img :src="activeInfo.logo" />
          </div>
        </el-col>
        <el-col :span="15" :offset="1">
          <div class="" style="text-align: left; width: 93%">
            <h1 class="fb mb0">{{ activeInfo.title }}</h1>
            <div class="fs13 mb20">创建时间：{{ activeInfo.time }}</div>
            <div class="sel">
              <b> 请选择是否开启随机播放讲解视频 :</b>

              <el-select v-model="israndom" placeholder="请选择是否开启随机播放讲解视频">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div class="sel" style="margin-top: 10px">
              <el-select v-model="chooseRoom" placeholder="请选择直播间平台" @change="selChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div v-show="isAddr" class="df" style="margin-top: 10px">
              <el-input v-model="liveAddr" placeholder="请输入直播间地址"></el-input>
            </div>
            <div v-show="isRoomId" class="df" style="margin-top: 10px">
              <el-input v-model="inputLive" placeholder="请输入房间号"></el-input>
            </div>
            <div class="mt5 df">
              <i class="el-icon-video-camera" style="margin-right: 5px"></i>
              直播间：{{ roomId }}
              <div v-show="showStatus">
                <span v-show="roomId" class="statusBox success"> 获取成功</span>
                <span v-show="!roomId" class="statusBox error">获取中</span>
              </div>
            </div>
            <div class="dialog-footer" style="margin-top: 0.65rem; text-align: right">
              <el-button style="width: 92px" @click="handleClose">取 消</el-button>
              <el-button type="primary" :disabled="disabled" @click="openMyLive">开启直播</el-button>
              <el-button type="success" style="margin-left: 5px" @click="getLiveRoom">获取弹幕</el-button>

              <!-- <el-button type="primary" @click="send">发送消息</el-button> -->

              <!-- <div @click="fun(1)">test1</div>
              <div @click="fun(2)">test2</div>
              <div @click="fun(3)">test2</div> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { getlive, dellive, editlive, createlive, get_live_video } from "@/api/live.js";

export default {
  data() {
    return {
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      openLive: false,
      showStatus: false,
      disabled: false,
      isopen: false,
      fullscreenLoading: false,
      israndom: false,
      isAddr: false,
      isRoomId: false,
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      options: [
        {
          value: "dy",
          label: "抖音",
        },
        {
          value: "tk",
          label: "tiktok",
        },
        {
          value: "sph",
          label: "视频号",
        },
        {
          value: "ks",
          label: "快手",
        },
        {
          value: "bz",
          label: "哔哩哔哩",
        },
        {
          value: "qita",
          label: "其他",
        },
      ],
      value: "",
      isPlay: false,
      videoList: [],
      views: {
        type: "vue",
        content: "/#/special/subwindow",
        windowName: "window-vue",
        windowTitle: "vue window",
      },
      id: null,
      socket1: null,
      type: null,
    };
  },
  mounted() {
    // 渲染视频
    this.init();
    this.getlive();
    this.test();
  },
  methods: {
    test() {
      this.$ipc.invoke(ipcApiRoute.checkFileExistence, "a.mp4").then((res) => {
        console.log("aaa:", res);
      });
    },
    fun(i) {
      console.log("1231", this.isPlay);
      if (!this.isPlay) {
        switch (i) {
          case 1:
            this.sendTosubWindow({ saveName: this.liveInfo.keywordList[0].saveName, type: "insertVideo", isPlay: true });
            break;
          case 2:
            this.sendTosubWindow({ saveName: this.liveInfo.keywordList[1].saveName, type: "insertVideo", isPlay: true });
            break;
          case 3:
            this.sendTosubWindow({ saveName: this.liveInfo.keywordList[2].saveName, type: "insertVideo", isPlay: true });
            break;

          default:
            break;
        }
        this.isPlay = true;
      }
    },
    init() {
      // 监听 窗口2 发来的消息
      this.$ipc.removeAllListeners(specialIpcRoute.window2ToWindow1);
      this.$ipc.on(specialIpcRoute.window2ToWindow1, (event, arg) => {
        console.log("监听 窗口2 发来的消息");
        this.isPlay = false;
      });
      this.$ipc.removeAllListeners(ipcApiRoute.ipcSendMsg);
      this.$ipc.on(ipcApiRoute.ipcSendMsg, (event, res) => {
        console.log("res:", res);

        if (res.type == "close") {
          this.disabled = false;
          if (res.content == "点击关闭窗口") {
            this.openLive = false;
            if (this.socket1) this.closeWs();
          }
        } else if (res.type == "downloadDone") {
          this.downloadList.map((i) => {
            if (i.file_id == res.file_id) {
              i.isdownload = true;
              console.log("downloadDone:", i);
            }
          });
        }
      });
    },
    getItem(item) {
      const loading = this.$loading({
        lock: true,
        text: "正在获取直播资源",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.downloadList = [];
      this.activeInfo = item;
      this.liveInfo = {
        livelist: [],
        keywordList: [],
      };
      let p1 = get_live_video({ live_id: item.id, type: 1 });
      let p2 = get_live_video({ live_id: item.id, type: 2 });
      Promise.all([p1, p2]).then((res) => {
        console.log("promise", res);
        res[0].data.map((i) => {
          if (i.statusa == 3) {
            i.saveName = i.file_id + "hc.mp4";
            this.liveInfo.livelist.push(i);
          }
        });
        res[1].data.map((i) => {
          if (i.statusa == 3) {
            i.saveName = i.file_id + "hc.mp4";
            this.liveInfo.keywordList.push(i);
          }
        });
        let arr = [...this.liveInfo.livelist, ...this.liveInfo.keywordList];
        arr.map((r) => {
          if (r.statusa == 3) {
            let obj = {
              ...r,
              isdownload: false,
            };
            this.downloadList.push(obj);
          }
        });
        this.$ipc.invoke(ipcApiRoute.downloadVideo, this.downloadList).then((r) => {
          console.log("downloadList:", r);
          this.downloadList = [];
          this.downloadList = r.vlist;
          this.$store.commit("setSavePath", r.path);
        });

        setTimeout(() => {
          this.openLive = true;
          loading.close();
        }, 2000);
      });
    },
    selChange(e) {
      console.log("e", e);
      switch (e) {
        case "sph":
          this.isAddr = false;
          this.isRoomId = false;
          this.liveAddr = "https://channels.weixin.qq.com/platform/live/home";
          break;
        case "qita":
          this.isAddr = false;
          this.isRoomId = false;
          break;
        default:
          this.isAddr = true;
          this.isRoomId = false;
          break;
      }
    },
    getLiveRoom() {
      if (!this.chooseRoom) {
        this.$message.error("请选择直播间类型再获取");
        return;
      }
      if (!this.disabled) {
        this.$message.error("请点击开启直播按钮获取弹幕");
        return;
      }
      // if (!this.inputLive) {
      //   this.$message.error("请输入直播间地址");
      //   return;
      // }
      if (this.chooseRoom == "qita") {
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在获取直播间",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$ipc.invoke(ipcApiRoute.closeSoftware).then((r) => {
        setTimeout(() => {
          this.$ipc.invoke(ipcApiRoute.editLocalIniFile, this.liveAddr).then((r) => {
            console.log("editLocalIniFile", r);
            loading.close();

            this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r1) => {
              console.log("resourcePath:", r1);
              if (r1) {
                // this.socket1 = new WebSocket("ws://127.0.0.1:9999/" + this.inputLive);
                this.socket1 = new WebSocket("ws://127.0.0.1:9999/");

                console.log(this.socket1, 111);
                this.socket1.addEventListener("open", (event) => {
                  this.socket1.send("Hello Server!");
                });

                this.socket1.addEventListener("message", (event) => {
                  console.log("event", event);
                  let res = JSON.parse(event.data);
                  if (res.status == 1 && res.type == "tips" && res.tips.indexOf("获取roomid成功") != -1) {
                    this.roomId = res.room_id;
                    this.showStatus = true;
                    this.$message.success("开始监听直播间");
                  }
                  if (res.status == 0 && res.tips == "未开播") {
                    this.$message.error("检测到该直播间未开播");
                  }

                  console.log("res;", res);
                  if (res.content) {
                    let cotent1 = res.content;
                    console.log("获取到消息", cotent1);
                    console.log("key", this.liveInfo.keywordList);
                    console.log("isplay", this.isPlay);
                    this.liveInfo.keywordList.map((i) => {
                      if (i.keyword.indexOf(cotent1) != -1) {
                        if (!this.isPlay) {
                          this.isPlay = true;
                          console.log("监听到关键词");
                          this.sendTosubWindow({ saveName: i.saveName, type: "insertVideo", isPlay: true });
                        }
                      }
                    });
                  }
                });
              }
            });
          });
        }, 2000);
      });
    },
    closeWs() {
      console.log(1123);
      this.socket1.close();
    },
    openMyLive() {
      const loading = this.$loading({
        lock: true,
        text: "直播资源校验中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (this.downloadList.length == 0) {
        this.$message.warning(`该直播方案暂无视频请添加后再开启直播！`);
        loading.close();
        return;
      }
      let idx = 0;
      this.downloadList.map((i) => {
        if (i.isdownload == false) idx++;
      });
      loading.close();

      if (idx == 0) {
        this.$ipc.invoke(ipcApiRoute.createWindow, this.views).then((r) => {
          console.log(r);
          this.id = r;
          this.disabled = true;
        });
        setTimeout(() => {
          console.log("isran", this.israndom);
          this.sendTosubWindow({ liveInfo: this.liveInfo, path: this.$store.state.SavePath, israndom: this.israndom });
        }, 2000);
        console.log("lise", this.liveInfo);
      } else {
        this.$message.warning(`直播资源下载未完成，当前有${idx}个视频下载中！`);
        loading.close();
      }
    },
    sendTosubWindow(e) {
      if (e) {
        this.$ipc.sendTo(this.id, specialIpcRoute.window1ToWindow2, e);
      } else {
        this.$ipc.sendTo(this.id, specialIpcRoute.window1ToWindow2, { msg: "窗口1通过 sendTo 给窗口2发送消息", type: "insertVideo" });
      }
    },
    handleClose() {
      this.$confirm("确认关闭,确认将关闭直播？")
        .then((_) => {
          // done();
          this.roomId = null;
          this.showStatus = false;
          this.inputLive = "";
          this.liveAddr = "";
          this.chooseRoom = "";
          this.isPlay = false;
          if (this.socket1) this.closeWs();
          this.openLive = false;
          this.$ipc.invoke(ipcApiRoute.closeWindow).then((r) => {
            console.log(r);
          });
        })
        .catch((_) => {});
    },
    add() {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }

      switch (this.type) {
        case "rename":
          editlive({
            live_id: this.activeI.id,
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", this.activeI.id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        default:
          createlive({
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", res.data.live_id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
      this.ipt = "";
    },
    getlive() {
      getlive({
        page: 1,
        item: 1000,
      }).then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(command) {
      this.type = command;
      switch (command) {
        case "edit":
          this.$emit("add", this.activeI.id);
          break;
        case "rename":
          this.title = "修改直播方案名称";
          this.dialogVisible = true;
          this.ipt = this.activeI.title;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              dellive({
                live_id: this.activeI.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => {});
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.live {
  .statusBox {
    padding: 0.06rem;
    border-radius: 0.1rem;
    font-size: 12px;
  }

  .success {
    background: #67c23a;
    color: #fff;
  }

  .error {
    background: #f56c6c;
    color: #fff;
  }

  /deep/.el-dialog__body {
    padding-top: 0px;
  }

  /deep/.el-input__inner {
    border: 0;
    background: hsl(217, 40%, 96%);
  }

  .sel {
    /deep/.el-input__inner {
      width: 26vw;
    }
  }
}

/deep/.el-textarea__inner {
  background: #f1f4f9 !important;
}

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-main {
  padding: 0.3rem !important;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.box {
  height: 12.65rem;
  overflow: scroll;
}

.item {
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  background: #fff;
  border-radius: 0.1rem;
  height: 6.3rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.item .desc {
  padding: 10px;
  text-align: left;
}

.item .tools {
  position: relative;
  z-index: 99;
  width: 0.53rem;
  height: 0.53rem;
  line-height: 0.53rem;
  background: #fff;
  border-radius: 4px;
  top: -5.85rem;
  left: 2.4rem;
}

.item img {
  width: 3.39rem;
  height: 5.1rem;
  border-radius: 0.1rem 0.1rem 0 0;
}

.item:last-child,
.item:nth-child(5n) {
  margin-right: 0;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.imgBg {
  img {
    width: 3.5rem;
    height: 5.5rem;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 0.3rem;
    background: #f1f4f9;
    height: 4.9rem;
    overflow: scroll;

    /deep/.el-button {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    img {
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}
</style>
