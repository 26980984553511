import request from '@/utils/request.js'
//获取直播方案列表
export const getlive = (params) => request.post('/api/live/get_live_list', params)
// 创建直播方案
export const createlive = (params) => request.post('/api/live/create_live', params)
// 修改直播方案
export const editlive = (params) => request.post('/api/live/edit_live', params)
// 删除直播方案
export const dellive = (params) => request.post('/api/live/del_live', params)
// 获取直播方案信息
export const getliveoption = (params) => request.post('/api/live/get_live', params)
// 获取直播方案视频列表
export const get_live_video = (params) => request.post('/api/live/get_live_video', params)
// 添加直播方案视频
export const add_live_video = (params) => request.post('/api/live/add_live_video', params)
// 删除直播方案视频
export const del_live_video = (params) => request.post('/api/live/del_live_video', params)
// 获取直播方案添加时视频列表
export const get_live_video_list = (params) => request.post('/api/live/get_live_video_list', params)


