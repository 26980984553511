<template>
  <div class="container">
    <el-row>
      <el-col :span="4">
        <div style="height: 14.61rem; background: #fff">
          <el-menu :default-active="active" class="el-menu-vertical-demo" @select="select">
            <el-menu-item index="1">
              <span slot="title">直播方案</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>

      <el-col :span="20">
        <div>
          <div>
            <live-list ref="liveList" v-show="showList" @add="add"></live-list>
            <addlive-option :liveId="liveId" v-show="!showList" @showList="add"></addlive-option>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import liveList from "./components/liveList.vue";
import addliveOption from "./components/addliveOption.vue";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  components: {
    liveList,
    addliveOption,
  },
  data() {
    return {
      active: "1",
      liveId: null,
      showList: true,
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.$ipc.invoke(ipcApiRoute.openSoftware,'app.exe').then((r) => {
        console.log("resourcePath:", r);
      });
    },
    select(e) {
      this.active = e;
    },
    add(id) {
      this.liveId = id + "";
      this.showList = !this.showList;
      if (this.showList) {
        console.log(123);
        this.$refs.liveList.getlive();
      }
    },
  },
};
</script>
<style scoped>
v-deep.is-active {
  color: #fff;
  background: #409eff;
}
</style>
