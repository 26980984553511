<template>
  <div class="bg" style="height: 14.31rem">
    <div class="bg-white" style="padding: 0.32rem">
      <el-page-header @back="goBack" content="编辑方案"></el-page-header>
    </div>

    <el-tabs v-model="activeName" @tab-click="getList">
      <el-tab-pane label="讲解视频" name="first">
        <div class="listBox" v-for="(i, idx) in videoList" :key="idx">
          <img :src="i.video_image" alt="">
          <div class="info">
            <div class="tit">{{ i.title }}</div>
            <div>
              <span>视频形象：{{ i.video_name }}</span>
              <span style="margin-left: 20px">声音：{{ i.video_name }}</span>
            </div>
            <div class="i_bottom">
              <span>时长：{{ i.video_name }}</span>
              <span style="margin-left: 20px">创建时长：{{ i.video_name }}</span>
            </div>
          </div>
          <div class="del" @click="del(i)"><i class="el-icon-delete"></i>删除</div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="互动视频" name="second">
        <div class="listBox dict" v-for="(i, idx) in dictList" :key="idx">
          <img :src="i.video_image" alt="">
          <div class="info">
            <div class="tit">关键词：{{ i.keyword }}</div>
            <div style="margin-bottom: 35px">视频标题：{{ i.title }}</div>
            <div class="df jc-sbn">
              <div>
                <span>视频形象：{{ i.video_name }}</span>
                <span style="margin-left: 20px">声音：{{ i.sound_name }}</span>
              </div>
              <div class="i_bottom" style="margin-top: 0">
                <span>时长：{{ i.times }}</span>
                <span style="margin-left: 20px">创建时长：{{ i.compose_time }}</span>
              </div>
            </div>
          </div>
          <div class="del" @click="del(i)"><i class="el-icon-delete"></i>删除</div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="btn">
      <el-button type="primary" v-show="activeName == 'first'" @click="(dialogVisible = true), getVideoList()">选择讲解视频</el-button>
      <el-button type="primary" v-show="activeName != 'first'" @click="(dialogVisible = true), getVideoList()">选择互动视频</el-button>
    </div>

    <el-dialog :title="`添加${activeName == 'first' ? '讲解' : '互动'}视频`" :visible.sync="dialogVisible" width="70%" :show-close="false">
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input v-model="search" placeholder="输入关键词搜索视频" suffix-icon="el-icon-search" @blur="handleSelect" @keyup.enter.native="handleSelect"></el-input>
      </div>
      <el-checkbox-group v-model="checkList">
        <div v-show="activeName == 'first'" class="dialog">
          <div v-for="(i, idx) in descList" :key="idx" class="listBox">
            <div class="check">
              <el-checkbox :key="i.id" :label="i.id"><br /></el-checkbox>
            </div>
            <img :src="i.video_image" alt="">
            <div class="info">
              <div class="tit">{{ i.title }}</div>
              <div>
                <span>视频形象：{{ i.video_name }}</span>
                <span style="margin-left: 20px">声音：{{ i.timbre_name }}</span>
              </div>
              <div class="i_bottom">
                <span>时长：{{ i.times }}</span>
                <span style="margin-left: 20px">创建时间：{{ i.create_time }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-checkbox-group>
      <el-checkbox-group v-model="checkaList">
        <div v-show="activeName != 'first'" class="dialog">
          <div v-for="(i, idx) in interactList" :key="idx" class="listBox dict">
            <div class="check">
              <el-checkbox :key="i.id" :label="i.id"><br /></el-checkbox>
            </div>
            <img :src="i.video_image" alt="">
            <div class="info">
              <div class="tit">关键词：{{ i.keyword }}</div>
              <div style="margin-bottom: 35px">视频标题：{{ i.title }}</div>
              <div class="df jc-sbn">
                <div>
                  <span>视频形象：{{ i.name }}</span>
                  <span style="margin-left: 20px">声音：{{ i.timbre_name }}</span>
                </div>
                <div class="i_bottom" style="margin-top: 0">
                  <span>时长：{{ i.times }}</span>
                  <span style="margin-left: 20px">创建时长：{{ i.create_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="add">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { get_live_video, add_live_video, del_live_video, get_live_video_list } from "@/api/live.js";
import { getVideo } from "@/api/video.js";

export default {
  props: {
    liveId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      activeName: "first",
      ipt: "",
      live_id: "",
      search: "",
      videoList: [],
      dictList: [],
      descList: [],
      interactList: [],
      checkList: [],
      checkaList: [],
    };
  },
  watch: {
    liveId(nVal) {
      if (nVal != "undefined") {
        this.live_id = nVal;
        this.getList();
      }
    },
  },
  mounted() {
    // 渲染视频
  },
  methods: {
    getList() {
      let param = {
        type: this.activeName == "first" ? "1" : "2",
        live_id: this.live_id,
      };
      get_live_video(param).then((res) => {
        if (res.code == "200") {
          console.log(this.activeName);
          if (this.activeName == "first") {
            this.videoList = res.data;
          } else {
            this.dictList = res.data;
            console.log(this.dictList);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getVideoList() {
      console.log(11);
      let param = {
        type: this.activeName == "first" ? "1" : "2",
        live_id: this.live_id,
      };
      get_live_video_list(param).then((res) => {
        if (res.code == "200") {
          let data = [];
          res.data.list.map((i) => {
            let obj = {
              ...i,
              select: false,
            };
            data.push(obj);
          });
          if (this.activeName == "first") {
            this.descList = data;
          } else {
            this.interactList = data;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goBack() {
      this.$emit("showList");
    },
    handleSelect() {
      console.log(11);
    },
    close() {
      this.checkList = [];
      this.checkaList = [];
      this.dialogVisible = false;
    },
    del(i) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del_live_video({
            live_id: this.live_id,
            video_id: i.id,
            type: this.activeName == "first" ? "1" : "2",
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    add() {
      let param = {
        video_ids: this.activeName == "first" ? this.checkList.join(",") : this.checkaList.join(","),
        type: this.activeName == "first" ? "1" : "2",
        live_id: this.live_id,
      };
      add_live_video(param).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getList();
          this.checkList = [];
          this.checkaList = [];
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-tabs__content {
  position: relative;
  height: 83vh;
  overflow: scroll;
}

/deep/.el-dialog {
  border-radius: 8px;

  .el-dialog__body {
    padding: 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    height: 60vh;
    overflow: scroll;
  }
}

/deep/.el-tabs__header {
  position: relative;
  margin: 0 0 15px;
  background: #fff;
  padding: 0 20px;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 4%;
  right: 3%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dialog {
  .listBox {
    margin: 0;
    border-bottom: 1px solid #ebebeb;
    box-shadow: none;
    border-radius: 0;

    .check {
      margin-right: 0.4rem;
      line-height: 2.5rem;
    }
  }
}

.listBox {
  background: #fff;
  width: 97%;
  padding: 15px;
  margin: 0 auto 0.4rem;
  border-radius: 2px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;

  .info {
    margin-left: 0.4rem;
    text-align: left;
    width: 40vw;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.32rem;
    }

    .i_bottom {
      margin-top: 0.58rem;
      font-size: 14px;
    }
  }

  img {
    width: 3.15rem;
    height: 4.8rem;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .del {
    color: rgb(245, 108, 108);
    margin-left: 12vw;
    align-self: center;
    cursor: pointer;
  }
}

.listBox:last-child {
  margin-bottom: 0;
}

.dict {
  background: #fff;
  width: 97%;
  padding: 15px;
  margin: 0 auto 0.4rem;
  border-radius: 2px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;

  .info {
    width: 55vw;

    .tit {
      margin-bottom: 0.2rem;
    }

    .i_bottom {
      margin-top: 0;
    }
  }

  .del {
    color: rgb(245, 108, 108);
    margin-left: 3vw;
    width: 5vw;
    align-self: center;
    cursor: pointer;
  }
}

.btn {
  position: absolute;
  top: 1.1rem;
  right: 0.2rem;

  /deep/.el-button {
    padding: 8px 12px;
  }
}
</style>
